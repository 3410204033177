var Link = ReactRouter.Link;

var App = React.createClass({
    displayName: 'App',
    getInitialState() {
        return {
            klbs: 'none'
        };
    },
    componentDidMount() {
    	this.serverRequest = $.getJSON('/api/klb/', function (JSONdata) {
	    	this.setState({
	            klbs: JSONdata.klbs
	        });
    	}.bind(this));
    },
    componentWillUnmount() {
		this.serverRequest.abort();
	},
    render() {
        return (
        	<div>
	        	{this.props.children}
	        </div>
        );
    }
});