var Button = ReactBootstrap.Button;
var ProgressBar  = ReactBootstrap.ProgressBar;

var Question = React.createClass({
    displayName: 'Question',
    getInitialState(){
        return {
            question: this.props.question,
            answered: 0,
            correct : 0
        };
    },
    answerCheck(q, a) {
        var t              = this;
        this.serverRequest = $.getJSON('/api/quiz/question/' + q.id, function (JSONdata) {
			this.setState({
                answered      : a.id,
                correct       : JSONdata.correct[0].id,
                correct_answer: JSONdata.correct[0].answer
			})
		}.bind(this));
        window.setTimeout(
            () => {
                this.props.onAnswer(this.state.answered == this.state.correct);
            }, 1000
            );
	},
    componentWillReceiveProps(nextProps) {
        this.setState({
            question: nextProps.question
        });
    },
    render() {
        var answerCheck = this.answerCheck;
        var question    = this.state.question;
        var c           = this.state.correct;
        var answers     = "";
        if (this.state.question != 0) {
            answers = this.state.question.answers.map(function(answer){
                var valState = (!c || c != answer.id) ? "default" : "success";
                return (
                    <Button
                        name      ={question.id}
                        value     ={answer.id}
                        bsStyle   ={valState}
                        bsSize    ="large"
                        onClick   ={answerCheck.bind(null, question, answer)}
                        className ="col-xs-12"
                        block>
                        {answer.answer}
                    </Button>)
            });
        }
        else {
            answers = "Bah";
        }
    	return (
    		<div className="row well">
	    		<div className="col-xs-12">
	    			<p className="lead">{this.state.question.question}</p>
	    			<div>
	    			{answers}
	    			</div>
	    		</div>
	    	</div>
    	)
    },
});

var Quiz = React.createClass({
    displayName: 'Quiz',
    getInitialState(){
        return {
            quiz_id  : this.props.quiz,
            quiz     : [],
            questions: 0,
            question : 0,
            completed: 0,
            correct  : 0,
            score    : 0,
            done     : false,
            progrbar : "success"
        };
    },
    componentDidMount() {
    	var qid = (this.state.quiz_id !== undefined) ? this.state.quiz_id : 0;
        this.serverRequest = $.getJSON('/api/quiz/' + qid, function (JSONdata) {
            var len = (typeof(JSONdata.questions) !== 'undefined') ? JSONdata.questions.length : 0;
            var q   = (typeof(JSONdata.questions) !== 'undefined') ? JSONdata.questions[0] : 0;
	    	this.setState({
                quiz     : JSONdata,
                questions: len,
                question : q
	        });
    	}.bind(this));
    },
    componentWillUnmount() {
		this.serverRequest.abort();
	},
    updateScore(correct) {
        // Increment nr of completed questions
        var c = this.state.completed + 1;
        var corr = (correct) ? this.state.correct + 1 : this.state.correct;
        var total = this.state.questions;
        if (correct) {
            this.setState({progrbar:"success"});
        }
        else {
            this.setState({progrbar:"danger"});
        }
        // If we reach the last question, set this.state.done to true
        var qz = 'Quiz: ' + document.location.hash.substring(1).replace(/\?.*/, '');
        if (c >= total) {
            ga('send', 'event', qz, 'score', parseInt(corr/total*100));
            this.setState({
                done: true,
                score: corr/total
            });
        }
        else {
            // Update nr of completed questions and get next question
            this.setState({
                completed: c,
                score    : corr/c,
                correct  : corr,
                question : this.state.quiz.questions[c]
            });
        }
    },
    render() {
    	var q = "";
    	var title = "Krossapróf";
        var score = parseInt(this.state.score*100);
        var curr  = this.state.completed + 1;
        var progr = parseInt(curr/this.state.questions*100);
        var prlbl = curr + " af " + this.state.questions;
        var alert, msg;
        if (score <= 45) {
            alert = "row quiz-msg bad animated shake"
            msg   = "Þú þyrftir að skoða efnið aðeins betur."
        }
        else if (score <= 70) {
            alert = "row quiz-msg adequate animated pulse"
            msg   = "Gæti verið betra"
        }
        else if (score <= 85) {
            alert = "row quiz-msg better animated pulse"
            msg   = "Mjög gott"
        }
        else {
            alert = "row quiz-msg good animated tada"
            msg   = "Glæsilegt!"
        }
    	if (this.state.quiz.questions !== undefined) {
            if (!this.state.done) {
                q = <Question question={this.state.question} onAnswer={this.updateScore}/>
            }
            else {
                q = <div className={alert}>
                        <h3>Prófi lokið</h3>
                        <p>Þú gerðir <strong>{score}%</strong> rétt.</p>
                        <p>{msg}</p>
                    </div>;
            }
    		title = this.state.quiz.title;
    	}
        return (
        	<div className="quiz container">
        		<div className="col-md-8 col-md-offset-2 col-xs-12">
	        		<h2>{title}</h2>
                    <div className="row score-bar">
                        <ProgressBar bsStyle={this.state.progrbar} now={progr} label={prlbl}/>
                    </div>
		            {q}
	            </div>
	        </div>
        );
    }
});
