// Collecting the menu tiles together
var Menutiles = React.createClass({
	getInitialState() {
	    return {
	        chapters: this.props.chapters
	    };
	},
	render() {
		var klb = this.props.klb;
		var tiles;
		if (this.props.chapters != 'none'){
			tiles = this.props.chapters.map(function(chapter) {
					return (
						<Menutile
							klb     ={klb}
							key     ={chapter.nr}
							chapter ={chapter}/>
					)
				});
		}
		else {
			tiles = <p>Hleð...</p>
		}
		return (
			<div id="Menu-tile-container" className="flex">
				{tiles}
			</div>
		)
	}
});