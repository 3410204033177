var Tab         = ReactBootstrap.Tab;
var Row         = ReactBootstrap.Row;
var Col         = ReactBootstrap.Col;
var Nav         = ReactBootstrap.Nav;
var NavItem     = ReactBootstrap.NavItem;
var NavDropdown = ReactBootstrap.NavDropdown;
var MenuItem    = ReactBootstrap.MenuItem;
var Link        = ReactRouter.Link;

var Chapter = React.createClass({
    displayName: 'Chapter',
    getInitialState(){
        return {
            classes: "hidden",
            key    : '0',
            chapter: this.props.params.chapter,
            klb    : this.props.params.klb
        };
    },
    componentDidMount() {
        // Call the API for the data
        this.serverRequest = $.getJSON(
            '/api/klb/' + this.state.klb + '/chapter/' + this.state.chapter,
            function (JSONdata) {
            document.title = JSONdata.title;
                var ga_page = document.location.hash.substring(1).replace(/\?.*/, '');
                ga('send', 'pageview', ga_page);

                this.setState({
                    chapter: JSONdata,
                    key    : JSONdata.parts[0].slug
                });
            }.bind(this));
    },
    componentWillUnmount() {
        this.serverRequest.abort();
    },
    handleSelect(key) {
        var ga_page = document.location.hash.substring(1).replace(/\?.*/, '') + key;
        ga('send', 'pageview', ga_page);
        // We want different behaviour for the menubutton so we check for it
        this.setState({key: key});
    },
    renderTabs(parts) {
        return parts.map(function(part) {
            return (
                <NavItem eventKey={part.slug}>{part.title}</NavItem>
            )
        });
    },
    renderTabPanes(parts) {
        return parts.map(function(part) {
            console.log("Quiz = " + part.quiz);
            var quiz = ((typeof(part.quiz) !== 'undefined') && (part.quiz !== 0)) ? <Quiz quiz={part.quiz} /> : ""
            return (
                <Tab.Pane eventKey={part.slug}>
                    <ChapterContents contents={part.content} />
                    {quiz}
                </Tab.Pane>
            )
        });
    },
    renderDropDown(dropdown) {
        var items = dropdown.items.map(function(item) {
            return (<MenuItem eventKey={item.slug}>{item.title}</MenuItem>)
        });
        return (
            <NavDropdown
                eventKey ={dropdown.slug}
                title    ={dropdown.title}
                id       ="nav-dropdown-within-tab">
                {items}
            </NavDropdown>
        )
    },
    renderNavbar() {
        // Construct all the tabs using the Tab component from ReactBootstrap
        var tabs = [];
        var tabpanes = [];
        // We need to scope these functions for the forloop below
        var renderDropDown = this.renderDropDown;
        var renderTabPanes = this.renderTabPanes;
        // Go through all chapter parts
        if (typeof(this.state.chapter) != 'string') {
            tabs.push(this.renderTabs(this.state.chapter.parts));
            tabpanes.push(this.renderTabPanes(this.state.chapter.parts));
            if (typeof(this.state.chapter.dropdowns) !== 'undefined') {
                this.state.chapter.dropdowns.forEach(function(dropdown) {
                    // Render the dropdown menu
                    tabs.push(renderDropDown(dropdown));
                    tabpanes.push(renderTabPanes(dropdown.items));
                });
            }
        }

        // Create the MenuButton from our component
        // Push the menu button onto the tab list
        tabs.push(
            <li role="presentation" className="menubutt">
                <Link to={this.state.klb}>
                    <span className="glyphicon glyphicon-th-large menuicon no-space" aria-hidden="true"></span>
                </Link>
            </li>
            );
        return (
            <div>
                <Nav bsStyle="tabs">
                    {tabs}
                </Nav>
                <Tab.Content animation >
                    {tabpanes}
                </Tab.Content>
            </div>
        )
    },
    render() {
        return (
            <div id="Chapter" className="shadow animated bounceInUp">
                <div id="Chapter-banner">
                    <img src={this.state.chapter.banner} className='header-img'/>
                </div>
                <Tab.Container activeKey={this.state.key} onSelect={this.handleSelect} id="tabs" className="tabs">
                    {this.renderNavbar()}
                </Tab.Container>
                <div id="about"></div>
            </div>
        );
    }
});