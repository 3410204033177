// Here is where it all comes together, the main container
var Klb = React.createClass({
    displayName: 'Klb',
    getInitialState() {
        var klb = this.props.params.klb;
        return {
            klb           : klb,
            chapters      : 'none',
            chapter       : 'none',
            menuClasses   : "shadow animated bounceInDown"
        };
    },
    componentDidMount() {
        // Call the API for the data
        this.serverRequest = $.getJSON('/api/klb/' + this.state.klb + '/', function (JSONdata) {
            document.title = JSONdata.title;
            ga('send', 'pageview', JSONdata.title);
            this.setState({
                klb     : JSONdata,
                chapters: JSONdata.chapters,
                chapter : JSONdata.chapters[0]
            });
        }.bind(this));
    },
    componentWillReceiveProps(nextProps) {
        // First we check if we have a server request going and if the klb parameter in the url has changed
        if (typeof(this.serverRequest) != undefined && this.state.klb != nextProps.params.klb) {
            // Abort previous request
            this.serverRequest.abort();
            // Start a new request with the new klb parameter
            this.serverRequest = $.get('/api/klb/' + nextProps.params.klb + '/', function (JSONdata) {
                document.title = JSONdata.title;
                ga('send', 'pageview', document.location.hash.substring(1).replace(/\?.*/, ''));
                this.setState({
                    klb     : JSONdata,
                    chapters: JSONdata.chapters,
                    chapter : JSONdata.chapters[0]
                });
            }.bind(this));
        }
    },
    componentWillUnmount() {
        this.serverRequest.abort();
    },
    render() {
        var classes;
        if (this.props.children) {
            classes = "hidden";
        }
        else {
            classes = "shadow animated bounceInDown";
        }
        // Here we grab the background image from the API
        var style = {
          backgroundImage: 'url(' + this.state.klb.backgroundImg + ')',
        };
        return (
            <div className="outer" style={style} >
                <div id="Klb" className="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-12 col-xs-12">
                    <Menu
                        klb       ={this.state.klb}
                        banner    ={this.state.klb.headerImg}
                        chapters  ={this.state.chapters}
                        footerImg ={this.state.klb.footerImg}
                        vnr       ={this.state.klb.vnr}
                        classes   ={classes}/>
                    {this.props.children}
                </div>
            </div>
        );
    }
});
