var Router = ReactRouter.Router;
var Route  = ReactRouter.Route;

ReactDOM.render((
  	<Router>
	    <Route path="/:klb" component={Klb}>
	    	<Route path="/:klb/:chapter" component={Chapter}>
	    		<Route path="/:klb/:chapter/:part" component={Chapter} />
	    	</Route>
	    </Route>
  	</Router>
), document.getElementById('Target'))