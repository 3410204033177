// This is a menu tile which acts as a button that reveals the relevent chapter
var Link = ReactRouter.Link;
var Image = ReactBootstrap.Image;
var Menutile = React.createClass({
	getInitialState(){
        return {
            classes: "col-md-2 col-sm-4 col-xs-4 menutile animated"
        };
    },
	mouseEnter() {
		this.setState(
			{classes: 'col-md-2 col-sm-4 col-xs-4 menutile animated pulse'
		});
	},
	mouseLeave() {
		this.setState({
			classes: 'col-md-2 col-sm-4 col-xs-4 menutile animated'
		});
	},
	render() {
		var link = '/' + this.props.klb.slug + '/'+ this.props.chapter.slug + '/';
		return (
			<Link
				to           ={link}
				id           ={this.props.chapter.slug}
				className    ={this.state.classes}
				onMouseEnter ={this.mouseEnter}
				onMouseLeave ={this.mouseLeave}>
				<Image
					src={this.props.chapter.thumb}
					className="tile-img"
					circle />
				<p
					className               ="text-uppercase text-center tile-title hyphenate"
					dangerouslySetInnerHTML ={{__html: this.props.chapter.title}}></p>
			</Link>
		)
	}
});