// Here is where the menu comes together
var Menu = React.createClass({
	render() {
		return (
		<div id="Menu" className={this.props.classes}>
			<header> <img src={this.props.banner} className="main-header-img img-responsive" /> </header>
			<Menutiles klb={this.props.klb} chapters={this.props.chapters} />
			<footer>
				<div className="row flex">
					<div className="col-md-4 col-xs-8">
						<a href="https://mms.is">
							<img src="https://mms.is/sites/mms.is/themes/mms_theme/assets/images/menta-logo.png" className="img-responsive img-footer" />
						</a>
					</div>
					<div className="col-md-4 col-md-offset-4 col-xs-4">
						<p className="vnr">{this.props.vnr}</p>
					</div>
				</div>
			</footer>
		</div>
		)
	}
});